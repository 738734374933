export default [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue")
    },
    {
        path: "/download",
        name: "download",
        component: () => import("@/views/DownloadView.vue")
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/FaqView.vue")
    },
/*    {
        path: "/news",
        name: "news",
        component: () => import("@/views/NewsView.vue")
    },*/
/*    {
        path: "/news/:id",
        name: "news-details",
        component: () => import("@/views/NewsDetailsView.vue")
    },*/
    {
        path: "/shaders",
        name: "shaders",
        component: () => import("@/views/ShadersView.vue")
    },
    {
        path: "/support",
        name: "support",
        component: () => import("@/views/SupportView.vue")
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        beforeEnter() {
            window.location.href = '/documents/polityka-prywatnosci-i-cookies.pdf';
        }
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        beforeEnter() {
            window.location.href = '/documents/regulamin-serwisu-internetowego.pdf';
        }
    },
    {
        path: "/:catchAll(.*)",
        name: 'error-404',
        redirect: '/error-404',
    }
]